<template>
    <div v-if="showPrompt" class="install-prompt">
      <div class="prompt-content">
        <div style="display: flex; justify-content: flex-start; align-items: flex-start; gap: 12px;">
          <img :src="getAssetUrl('assets/icon.png')" style="flex-shrink: 0; width: 48px; border-radius: 4px;"/>
          <div>
            <!-- Messaggio personalizzato per iOS -->
            <p v-if="isIOS() && !isInstalledButInBrowser">
              Tocca <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="ios-share-icon">
                   <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                   <polyline points="16 6 12 2 8 6"></polyline>
                   <line x1="12" y1="2" x2="12" y2="15"></line>
                 </svg> e poi "Aggiungi a Home" per installare l'applicazione
            </p>
            <!-- Messaggio personalizzato per Android -->
            <p v-else-if="isAndroid() && !isInstalledButInBrowser">
              Tocca <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="android-menu-icon">
                   <circle cx="12" cy="12" r="1"></circle>
                   <circle cx="12" cy="5" r="1"></circle>
                   <circle cx="12" cy="19" r="1"></circle>
                 </svg> e poi "Installa app" o "Aggiungi a schermata Home"
            </p>
            <!-- Messaggio per app già installata -->
            <p v-else-if="isInstalledButInBrowser">Hai già installato l'app. Aprila ora.</p>
            <!-- Messaggio generico per altri casi -->
            <p v-else>Per una migliore esperienza di navigazione, installa l'app sul dispositivo</p>
          </div>
        </div>
        <div>
          <button @click="closePrompt" class="btn_close_popup">
            Chiudi
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import { getAssetUrl } from "@/utils";
  
  // Configuration
  const localStorageKey = 'pwa_installed';
  
  // State
  const showPrompt = ref(false);
  const isInstalledButInBrowser = ref(false);
  const canInstall = ref(false);
  const deferredPrompt = ref(null);
  
  // Helpers
  const isStandalone = () => {
    return (
      window.matchMedia('(display-mode: standalone)').matches || // Android Chrome
      window.navigator.standalone === true // iOS Safari
    );
  };
  
  const isIOS = () => {
    return /iphone|ipad|ipod/i.test(window.navigator.userAgent);
  };
  
  const isAndroid = () => {
    return /android/i.test(window.navigator.userAgent);
  };
  
  const isInBrowser = () => {
    return !isStandalone();
  };
  
  // Check if we should remember this user has installed
  const checkStoredInstallState = () => {
    const stored = localStorage.getItem(localStorageKey);
    if (stored) {
      const data = JSON.parse(stored);
      // Check if it's an iOS device that we previously marked as installed
      if (isIOS() && data.ios) {
        isInstalledButInBrowser.value = true;
        return true;
      }
      // Check if it's an Android device that we previously marked as installed
      if (isAndroid() && data.android) {
        isInstalledButInBrowser.value = true;
        return true;
      }
    }
    return false;
  };
  
  const storeInstallState = (installed = false) => {
    if (installed) {
      const currentData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      const data = { ...currentData };
      
      if (isIOS()) {
        data.ios = true;
      }
      if (isAndroid()) {
        data.android = true;
      }
      
      localStorage.setItem(localStorageKey, JSON.stringify(data));
    }
    // Non memorizziamo nulla se l'utente ha chiuso il banner
  };
  
  const checkInstallStatus = async () => {
    if (isStandalone()) {
      showPrompt.value = false;
      return;
    }
    
    // Check stored state first
    if (checkStoredInstallState()) {
      showPrompt.value = isInstalledButInBrowser.value;
      return;
    }
    
    // Try to detect if app is installed
    if ('getInstalledRelatedApps' in navigator) {
      // Android con Chrome
      try {
        const apps = await navigator.getInstalledRelatedApps();
        isInstalledButInBrowser.value = apps.length > 0;
        if (isInstalledButInBrowser.value) {
          storeInstallState(true);
        }
      } catch (error) {
        console.error('Error checking installed apps:', error);
      }
    } else if (isIOS()) {
      // Per iOS, dobbiamo usare altri metodi per rilevare l'installazione
      isInstalledButInBrowser.value = false;
      
      // Controlla localStorage per vedere se abbiamo già registrato un'installazione
      const data = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
      if (data.ios) {
        isInstalledButInBrowser.value = true;
      }
    } else {
      // Fallback generico
      isInstalledButInBrowser.value = false;
    }
    
    showPrompt.value = true;
  };
  
  const promptInstall = () => {
    if (deferredPrompt.value) {
      deferredPrompt.value.prompt();
      deferredPrompt.value.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          storeInstallState(true);
          showPrompt.value = false;
        }
        deferredPrompt.value = null;
      });
    } else if (isIOS()) {
      // Per iOS, mostriamo istruzioni perché iOS non ha una proper API di installazione
      alert('Per installare l\'app: 1) Tocca l\'icona "Condividi" 2) Seleziona "Aggiungi a Home"');
    }
  };
  
  const closePrompt = () => {
    // Chiudiamo temporaneamente il banner
    showPrompt.value = false;
    
    // Far riapparire il banner dopo 5 minuti
    setTimeout(() => {
      showPrompt.value = true;
    }, 300000); // 5 minuti in millisecondi
  };
  
  // Event handlers
  const handleBeforeInstallPrompt = (e) => {
    e.preventDefault();
    deferredPrompt.value = e;
    canInstall.value = true;
  };
  
  const handleAppInstalled = () => {
    storeInstallState(true);
    deferredPrompt.value = null;
    canInstall.value = false;
    showPrompt.value = false;
  };
  
  onMounted(() => {
    // Listen for 'beforeinstallprompt' event
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
    // Listen for 'appinstalled' event
    window.addEventListener('appinstalled', handleAppInstalled);
    
    // Check if browser or standalone
    if (isInBrowser()) {
      checkInstallStatus();
    }
  });
  
  onBeforeUnmount(() => {
    window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.removeEventListener('appinstalled', handleAppInstalled);
  });
  </script>
  
  <style scoped>
  .install-prompt {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    padding: 12px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.1);
  }
  
  .prompt-content {
    max-width: 600px;
    width: 100%;
    text-align: center;
  }
  
  .ios-share-icon, .android-menu-icon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 2px;
  }
  
  .btn_install_app {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: rgb(209 250 229);
    color: rgb(16 185 129);
    border: none;
    margin-top: 8px;
  }
  
  .btn_close_popup {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    background-color: rgb(254 226 226);
    color: rgb(239 68 68);
    border: none;
    margin-top: 8px;
  }
  </style>